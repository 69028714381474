
.ModalSave {
  /* width: 100%;
  height: 100%; */
  width: 100%;
  height: 100%;
  min-height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(120,0%,0%,0.53);
  position: absolute;
  top:0px;
  left:0px;
}

.ModalSave .SpinnerContainer {
  width: 100%;
  height: 100%;
  min-height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(120,0%,0%,0.53);
  position: absolute;
  top:0px;
  left:0px;
}
.ModalSave .SpinnerContainer div {
  margin-top: 16px;
  font-size: 18px;
  color: white;
}
.ModalSave .SpinnerContainer.Invisible {
  visibility: hidden;
  /* transition back currently does not work because it goes already to other screen. We could add this screen to the root element to solve it */
  opacity: 0;
  transition: opacity 1s;
}
.ModalSave .SpinnerContainer.Visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s;
}

.ModalSave .Email,
.ModalSave .Back
 {
  width: 70%;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-around;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.ModalSave .Email h1,
.ModalSave .Back h1 {
  color: #707070;
  font-size: 18px;
}
.ModalSave .Email label,
.ModalSave .Back div {
  color: #707070;
  font-size: 13px;
}
.ModalSave .Email input {
  border-radius: 5px;
  box-shadow: 1px 1px 1px #707070;
  margin: 10px;
  width: 100%;
  box-sizing: border-box;
}

.ModalSave .Email .Row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ModalSave .Email .Annuleer {
  background-color: #8A9294;
}

/* ErrorText is handled by global css */
/* .ModalSave .Email .ErrorText {

} */

.ModalSave .Email .Annuleer,
.ModalSave .Email .Verzend,
.ModalSave .Back button {
  min-width: 101px;
  /* font-size: 14px; */
}

.ModalSave .Email .Verzend:disabled {
  background-color: #C4C9CB;
  color: #8A9294;
  cursor: not-allowed;
}
.LabelFormMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  box-sizing: border-box;
  padding: 16px;
}

.LabelFormMobile > p {
  color: #707070;
  font-size: 15px;
}

.LabelFormMobile .Row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6px;

}

.LabelFormMobile .RowForButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.LabelFormMobile .RowForButtons .Annuleer {
  background-color: #8A9294;
}

.LabelFormMobile .RowForButtons .Annuleer,
.LabelFormMobile .RowForButtons .Klaar {
  min-width: 101px;
  /* font-size: 14px; */
}

.ShowMoreButton {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin:0px;
  padding:0px;
}

.RowForButtons .Klaar:disabled {
  background-color: #8A9294;
  color: #707070;
  cursor: not-allowed;
}

.RowForButtons .KlaarButtonContainer {
  display: flex;
  position: relative;
  flex-direction: column;
}

.RowForButtons .Klaar+span{
  position: absolute;
  top: -100px;
  color: #d2604e;
  background-color: white;
  visibility: hidden;
}
.RowForButtons .Klaar:disabled:hover+span {
  visibility: visible;
}
/* .Desktop .RowForButtons .Klaar+span {
  top: 40px;
}
.Mobile .RowForButtons .Klaar+span {
  top: -100px;
} */
/* .RowForButtons .Klaar:disabled:hover {
  background-color: red;
} */
/* .RowForButtons .Klaar:disabled:hover::after {
  content: "Kies eerst het type in het dropdown menu"; 
  color: #d2604e;
  position: absolute;
  top:10px;
} */

/* does not work */
/* .RowForButtons .Klaar:disabled:focus {
  background-color: green;
} */

.LabelFormMobile ul {
  list-style-type: none;
  padding-left: 0;
}

.LabelFormMobile .Row .ColumnAssetType {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
}

/* This is to make it smaller in case of "show more" checkbox is checked */
.LabelFormMobile .Row .ColumnAssetTypeSmall {
  flex-basis: 40%;
}
/* .LabelFormMobile .Row .ColumnAssetTypeSmall ~ .ColumnAssetArea, */
.LabelFormMobile .Row .ColumnAssetTypeSmall ~ .ColumnAssetStorage {
  flex-basis: 20%;
}

.LabelFormMobile .Row .ColumnAssetType select {
  width: 100%;
}
.LabelFormMobile .Row .ColumnAssetArea {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 30%;
}
.LabelFormMobile .Row .ColumnAssetArea input {
  max-width: 70px;
}
.LabelFormMobile .Row .ButtonRemoveAsset {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 10%;
}
.LabelFormMobile .Row .ButtonRemoveAsset button {
  background-color: transparent;
  border: none;
  color: #797979;
  font-size: 18px;
}

.LabelFormMobile .AssetRowHeader .ColumnAssetArea {
  color: #3F3F3F;
  font-size: 16px;
  text-decoration: underline;
  text-align: left;
}

.LabelFormMobile .Row .NewAssetButton {
  width: 100%;
  font-size: 14px;
}


/* className="Row AssetRow AssetRowHeader"
>
  <div
    className="ColumnAssetType"
  >

  </div>
  <div
    className="ColumnAssetArea" */
.ColumnAssetArea > div ,
.ColumnAssetStorage > div {
  position: relative;
}

.AssetList .Row {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.AssetList .Row input,
.AssetList .Row select, 
.AssetList .Row .ColumnAssetType,
.AssetList .Row .ColumnAssetArea,
.AssetList .Row .ColumnAssetStorage {
  height: 40px;
  box-sizing: border-box;
  
}
.AssetList .Row .ColumnAssetType,
.AssetList .Row .ColumnAssetArea,
.AssetList .Row .ColumnAssetStorage {
  display: flex;
  align-items: center;
}

.AssetInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AssetInputContainer > div {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.AssetInputContainer > div > span {
  color: #3F3F3F;
  opacity: 0.39;
  position: absolute;
}

.ColumnAssetArea .AssetInputContainer > div > span {
  left: -30px;
}
.ColumnAssetStorage .AssetInputContainer > div > span {
  left: -36px;
}
.AssetList .Row.VeranderButtonRow {
  display: flex;
  justify-content: flex-end;
}

legend.Verander {
  color: #707070;
  font-size: 16px;
}


/* Remove dotted line 
https://stackoverflow.com/questions/19886843/how-to-remove-outline-border-from-input-button
For now not because of accesibility
*/
/* button{
  outline:none;
}
button::-moz-focus-inner {
  border: 0;
} */
/*  */

/* .TabActive {
  background-color: white;
  border-bottom-width: 0px;
} */
.Body {
  width: 100%;
  /* height: 100%;
  min-height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  position: relative;
}
.Body .Tile {
  margin-top: 16px;
  background-color: white;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 3px hsla(120,0%,0%,0.16);
}
.Desktop {
  display: none;
}

.Logos {
  visibility: hidden;
  /* display: none; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  margin-top: auto;
  flex-wrap: wrap;
}
.Logos img {
  height: 5vh;
}

.StandardButton {
  color: white;
  font-size: 18px;
  background-color: #0086A6;
  border: none;
  border-radius: 17px;
  box-shadow: 0px 3px 3px hsla(120,0%,0%,0.16);
  padding: 8px;
}

.SearchAddressFormContainerTop {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
}

.SearchAddressFormContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%
}
.BackgroundImageSearchAddressForm {
  display: none;
}

.SearchAddressForm form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ModalYoutube .YoutubeFrame {
  background-color: #3D3D3D;
  /* HOW CAN WE CUSTOMIZE THIS ? */
  /* min-width: 40%; */
  /* max-width: 90%; */
  width: 80%;
  min-height: 40%;
  max-height: 80%;

  padding: 18px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}

.SearchAddressForm form .ButtonToggleStreetPostcode {
  color: #707070;
  font-size: 16px;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 20px;
  text-decoration: underline;
}

@media only screen and (min-width: 900px) {
  .Mobile {
    display: none;
  }
  .Body {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
  }
  .Desktop {
    display: initial;
  }
  .Body .Tile {
    /* Somehow the info tiles will align in desktop view when they are 95%. Temporal workaround untill these tiles get desktop layout  */
    width: 95%;
  }
  .Logos {
    visibility: visible;
  }
  .StandardButton {
    padding: 12px;
  }
  .SearchAddressFormContainer {
    width: 50%;
  }
  .BackgroundImageSearchAddressForm {
    display: flex;
    width: 50%;
  }
  .SearchAddressForm form {
    align-items: flex-end;
    padding-right: 5%;
  }
  .ModalYoutube .YoutubeFrame {
    width: 40%;
  }
  .SearchAddressForm form .ButtonToggleStreetPostcode {
    text-align: right;
    margin-right: 0;
    padding-right: 0;
  }
}

.App button {
  cursor: pointer;
}
.App button:disabled {
  cursor: not-allowed;
}


.TabActive button {
  background-color: white;
  border-bottom-width: 0px;
}

.ErrorText {
  color: #d2604e;
}

/* .Desktop.StandardButton.NieuwLabelOpslaan {
  font-size: 14px;
} */

.Desktop.StandardButton.NieuwLabelOpslaan+span {
  visibility: hidden;
  color: #d2604e;
}
.Desktop.StandardButton.NieuwLabelOpslaan:disabled {
  color: #707070;
  background-color: #8A9294;
}
.Desktop.StandardButton.NieuwLabelOpslaan:disabled+span {
  visibility: inherit;
}

.StandardTile {
  margin-top: 16px;
  background-color: white;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0px 3px 3px hsla(120,0%,0%,0.16);
}

html, body, #root, .App {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: #EEFAFF;
  font-family: 'Rigid Square', 'Regular', 'Roboto', sans-serif;
  margin : 0 ;
}

.App {
  /* margin-left: 100px;
  margin-top: 20px; */
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: auto;
  
}

.InfoPage {
  box-sizing: border-box;
  padding: 16px;
  color: #707070;
  font-size: 15px;
}


/* already declared at top of page */
/* @media only screen and (min-width: 800px) {
  .Body {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
  }
} */

.BodyHide {
  display: none;
}


.Body .Tile.Desktop.Currentlabel {
  width: 20%;
}
.Body .Tile.Desktop {
  width: 70%;
}

.Body .Button {
  margin-top: 16px;
  width: 90%;
  border-radius: 17px;
  box-shadow: 0px 3px 3px hsla(120,0%,0%,0.16);
}
.Body .Tile .Tab {
  width: 100%;
  background-color: transparent;
  height: 62px;
  border: none;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;

  color: #3F3F3F;
  font-size: 20px;
}

.Body .Tile .Tab .PlusIcon {
  font-size: 40px;
}
.Body .Tile .TabActive .PlusIcon {
  visibility: hidden;
}
.Body .Tile .TabInActive + div {
  display: none;
}
/* this styling should be done in labelform */
/* .Body .Tile .Tab + div {
  box-sizing: border-box;
  padding: 16px;
} */
/* _____________________________________________ BackModal */
.ModalBack {
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(120,0%,0%,0.53);
  position: absolute;
  top:0px;
  left:0px;
}
.ModalBack .BackConfirm {
  width: 70%;
  /* height: 210px; */
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-around;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.ModalBack .BackConfirm h1 {
  color: #707070;
  font-size: 18px;
}
.ModalBack .BackConfirm p {
  color: #707070;
  font-size: 16px;
}
.ModalBack .BackConfirm .Row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ModalBack .BackConfirm .Blijf {
  background-color: #8A9294;
}
.ModalBack .BackConfirm .DoNotSave {
  background-color: #d2604e;
}
/* _____________________________________________ Youtube */
.ModalYoutube {
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(120,0%,0%,0.53);
  position: absolute;
  top:0px;
  left:0px;
}


.ModalYoutube .YoutubeFrame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ________________________________________________ADDRESS HEADER */
.AddressHeader {
  position: relative;
  width: 100%;
  min-height: 14vh;
  height: 14vh;
  background-color: #0086A6;
  color: white;
  
}
.AddressHeader button {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;

  font-size: 40px;
  background-color: transparent;
  border: none;
  color: white; /*why does this not inherit from parent div?*/
}
.AddressHeader > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AddressHeader .SelectedAddress {
  font-size: 24px;
  text-align: center;
}


/* _________________________________________________________ Search Address Form */



.SearchAddressForm {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  margin: auto;
}
.HideSearchAddressForm {
  display: none;
}

.SearchAddressForm .SpinnerContainer {
  width: 100%;
  height: 100%;
  min-height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(120,0%,0%,0.53);
  position: absolute;
  top:0px;
  left:0px;
}
.SearchAddressForm .SpinnerContainer div {
  margin-top: 16px;
  font-size: 18px;
  color: white;
}
.SearchAddressForm .SpinnerContainer.Invisible {
  visibility: hidden;
  /* transition back currently does not work because it goes already to other screen. We could add this screen to the root element to solve it */
  opacity: 0;
  transition: opacity 1s;
}
.SearchAddressForm .SpinnerContainer.Visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s;
}



.SearchAddressForm form h1 {
  /* margin-top: 10px;  */
  margin-bottom: 10px; /*To make the subheader closer to the header */
  color: black;
  font-size: 30px;
}
/* Selects the span next to the header */
.SearchAddressForm form h1 + span {
  /*  push content below subheader down */
  margin-bottom: 50px;
  color: black;
  font-size: 20px
}

.SearchAddressForm form .YoutubeButton{
  background-color: transparent;
  border: none;
  outline: none;
  margin-bottom: 50px;
  cursor: pointer;
}

.SearchAddressForm form .YoutubeButton div {
  color: #707070;
  font-size: 20px;
  margin-bottom: 4px;
}

.SearchAddressForm form legend {
  color: #707070;
  font-size: 16px;
  margin-bottom: 16px;
}

.SearchAddressForm form .InputBlock {
  min-width: 278px; 
}

.SearchAddressForm form .InputBlock .Row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6px;
}
.SearchAddressForm form .InputBlock .Row .LabelLeft,
.SearchAddressForm form .InputBlock .Row .LabelRight {
  color: black;
  font-size: 16px;
}
.SearchAddressForm form .InputBlock .Row .LabelLeft {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
}
.SearchAddressForm form .InputBlock .Row .LabelRight {
  width: 40px;
  margin-left: 10px;
}
.SearchAddressForm form .InputBlock .Row input {
  color: #707070;
  font-size: 19px;
  border-radius: 3px;
  border-width: 0;
  height: 31px;
}
.SearchAddressForm form .InputBlock .Row .LabelLeft + input {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 74px;
  max-width: 74px;
}
.SearchAddressForm form .InputBlock .Row .LabelRight + input {
  flex-grow: 0;
  flex-shrink: 0;
  /* how to make dynamic ?? */
  flex-basis: 50px;
  max-width: 50px;
}
.SearchAddressForm form .InputBlock .Row .LabelLeft + .InputWholeRow {
  /* flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 204px; */
  
  max-width: 204px;
  /* max-width: 100%; */
  flex: 1;
  min-width: 0;
}


.SearchAddressForm form .ButtonSearch {
  /* color: white;
  font-size: 18px;
  background-color: #0086A6;
  border: none; */
  width:116px;
  /* height: 35px; */
  margin-bottom: 20px;
  /* border-radius: 17px;
  margin-bottom: 20px; */
}

.SearchAddressForm form .ErrorText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SearchAddressForm form .ErrorText > div:first-child {
  font-weight: bold;
}
.SearchAddressForm .LinkOldSite {
  color: #707070;
  font-size: 16px;
}


/* __________________________________________________________ Search Address From List */
.SelectAddressFromList {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.SelectAddressFromList legend {
  color: #707070;
  font-size: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
}


.SelectAddressFromList ul {
  list-style-type: none;
  padding-left: 0;
}
.SelectAddressFromList button {
  min-height: 45px;
  margin-bottom: 10px;
  width: 90%;

}

/* ________________________________________________ ANY LABEL */
.AnyLabelVisual {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  box-sizing: border-box;
  max-height: 80vh;
}
.AnyLabelVisual .BackgroundImage {
  position: relative;
  width: 100%;
  box-sizing: border-box;


  /* display: flex;
  justify-content: flex-start;
  align-items: center; */

}
.AnyLabelVisual .BackgroundImage .Margin {
  width: 100%;
  height: 75vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AnyLabelVisual .BackgroundImage .Margin img {
  width: 90%;
  margin-top: 16px;
}
.AnyLabelVisual .BackgroundImage .Margin svg {
  display: flex;
  flex: 1;
  margin-top: 16px;
}


.AnyLabelVisual .Text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 27px;
  color: #3F3F3F;
}
.AnyLabelVisual .NoLabelYetLayOver {
  width: 100%;
  height: 100%;
  background-color: hsla(120, 0%, 100%, 0.50);
  position: absolute;
}

.AnyLabelVisual > div {
  /* fix ie11 bug that opslaan button is too much to left */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* _____________________________________________________ new BUTTON */

.NewButton {
  background-color: #0086A6;
  width: 100%;
  min-height: 50px; 
   height: 50px;
}
.Mobile.NewButton:disabled {
    color: #707070;
    background-color: #8A9294;
}
/* ___________________________________________- InfoTabs */

.InfoTabContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InfoTabContainer button {
  height: 62px;
  border: none;
  font-size: 19px;
  color: #3F3F3F;
}

.InfoTabContainer button{
  outline:none;
}
.InfoTabContainer button::-moz-focus-inner {
  border: 0;
}

.InfoTabContainer .Info {
  padding: 30px;
  font-size: 16px;
  color: #707070;
  box-sizing: border-box;
  /* move whole info tile op to overlap with button. Hacky.. */
  /* margin-top: -14px; */
}
.InfoTabContainer .Info pre {
  white-space: normal;
}

.Alert.NieuwLabelOpslaan:not([disabled]) {
  -webkit-animation: OPSLAAN-ANIMATION 1s alternate ease-in-out infinite; /* Safari 4+ */
  -moz-animation:    OPSLAAN-ANIMATION 1s alternate ease-in-out infinite; /* Fx 5+ */
  -o-animation:      OPSLAAN-ANIMATION 1s alternate ease-in-out infinite; /* Opera 12+ */
  animation:         OPSLAAN-ANIMATION 1s alternate ease-in-out infinite; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes OPSLAAN-ANIMATION {
  0%   { opacity: 0.4; }
  100%   { opacity: 1; }
  /* 50%   { opacity: 1; }
  100% { opacity: 0.4; } */
}
@-moz-keyframes OPSLAAN-ANIMATION {
  0%   { opacity: 0.4; }
  100%   { opacity: 1; }
}
@-o-keyframes OPSLAAN-ANIMATION {
  0%   { opacity: 0.4; }
  100%   { opacity: 1; }
}
@keyframes OPSLAAN-ANIMATION {
  0%   { opacity: 0.4; }
  100%   { opacity: 1; }
}

/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
